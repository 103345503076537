
//import FadeUp from './FadeUp.vue'
import Fade from './Fade.vue'

export default {
  methods: {
    scroll() {
      const aboutSection = document.getElementById("about");
      const headerOffset = 150;
      const elementPosition = aboutSection?.getBoundingClientRect().top;
      const offsetPosition = elementPosition! + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  },
  components: { Fade }
}
