
import About from '@/components/About.vue';
import Experience from '@/components/Experience.vue';
import Introduction from '@/components/Introduction.vue';
import Projects from '@/components/Projects.vue';
import Services from '@/components/Services.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HomeView',
  components: {
    Introduction,
    About,
    Experience,
    // Projects,
    Services
},
});
