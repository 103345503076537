
import { defineComponent } from "vue";
import Card from "./Card.vue";
import FadeUp from "./FadeUp.vue";


export default defineComponent({
    name: "Experience",
    components: {
    Card,
    FadeUp
},

});

