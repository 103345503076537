
import FadeUp  from "./FadeUp.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "About",
  components: {
    FadeUp
  }
});

