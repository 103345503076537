
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        imageSrc: {
            type: String,
            required: true,
        },
        className: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        company: {
            type: String,
            required: true,
        },
        place: {
            type: String,
            required: true,
        },
        date: {
            type: String,
            required: true,
        },
    },
    methods: {
        
    },
}); 
