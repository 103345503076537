
import Footer from "./components/Footer.vue";
import  NavigationBar  from "./components/NavigationBar.vue";
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App',
  components: {
    NavigationBar,
    Footer
}
});

