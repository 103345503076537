
import { defineComponent } from "vue";
import FadeUp from "./FadeUp.vue";


export default defineComponent({
    name: "Services",
    components: {
        FadeUp
    },
    data() {
        return {

        }
    },
    methods: {

    }
});
