
import { defineComponent } from "vue";

export default defineComponent({
    name: "FadeUp",
    props: {
        delay: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            observer: null as null | IntersectionObserver,
        };
    },
    mounted() {

        this.observer = new IntersectionObserver(this.onIntersection, {
            rootMargin: '0px',
            threshold: 0.5
        });

        this.observer.observe(this.$refs.fadeUp as Element);
    },
    beforeUnmount() {
        this.observer!.disconnect();
    },

    unmounted() {
        this.observer!.disconnect();
    },

    methods: {
        onIntersection(entries: any) {
            entries.forEach((entry: any) => {
                if (entry.intersectionRatio > 0) {
                    setTimeout(() => {
                        entry.target.classList.add('fade-up--active');
                        this.observer!.unobserve(entry.target);
                    }, this.delay);   
                }
            });
        }
    }
});
